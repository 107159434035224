import { useEffect, useState } from "react";
import Dialog from "../Dialog";
import Dropdown from "../Dropdown";
import TextField from "../TextField";
import { getValue } from "../../utils/object";
import { useMobile } from "../../hooks/mobile";
import { Colors } from "../../constants/colors";
import CloseIcon from "../../images/cross.svg";
import { useUserStore } from "../../stores/user";
import { Box, Typography } from "@material-ui/core";
import PhoneInput from "../MobileModalOtp/PhoneInput";
import countryCodes from "../../data/countryCodes.json";
import Button, { ButtonSize, ButtonType } from "../Button";
import { InviteUserRolesOption } from "../../pages/BlysTeam/Settings";
import { getCountryCode } from "../../helpers/mobile";
import { RecipientDataInterface, UserDataInterface } from "../../pages/BlysTeam/Settings";
import { Roles, RolesOptionsForManager, RolesOptionsForOwner } from "../../constants/blysTeam";


interface AddUserModalInterface {
  open: boolean;
  onClose: () => void;
  selectedUser: UserDataInterface | null;
  handleSaveClicked: (
    id: number | null,
    data: RecipientDataInterface,
    isManager: boolean,
    userOriginalRole?: string
  ) => unknown;
  isLoading: boolean;
  managerOptions: Array<any> | null;
  inviteUserRolesOption: Array<InviteUserRolesOption> | [];
}

const AddUserModal = ({
  open,
  onClose,
  selectedUser,
  handleSaveClicked,
  isLoading,
  managerOptions,
  inviteUserRolesOption,
}: AddUserModalInterface) => {
  const isMobile = useMobile();

  const [id, setId] = useState<number | null>(null);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [country, setCountry] = useState<string>("AU");
  const [userOriginalRole, setUserRole] = useState<string>("");
  const [phoneInfo, setPhoneInfo] = useState({
    numberOnly: "",
    mobileNumber: "",
  });
  const [phoneDialCode, setPhoneDialCode] = useState("+61");
  const [userId, setUserId] = useState<number | null>(null);

  const [isRecipientManager, setIsRecipientManager] = useState<boolean>(false);

  const { user } = useUserStore();
  const isAccountOwner = getValue(user, "role.label") === Roles.ACCOUNT_OWNER;
  const canInviteUser = getValue(user, "permission.canInviteUser");
  const userRole = getValue(user, "role.label");

  const ifEditUserLoggedInUser = () => {
    if (selectedUser?.email === user?.email) {
      return true;
    }
    return false;
  };

  const roleOptions = () => {
    switch (userRole) {
    case Roles.ACCOUNT_MANAGER:
      return RolesOptionsForManager;

    case Roles.HOME_CARE_PROVIDER:
    case Roles.AGED_CARE_SUPPORT_RECIPIENT:
    case Roles.DISABILITY_SUPPORT_RECIPIENT:
      const formatOptions = inviteUserRolesOption?.map((data) => ({
        title: data.title,
        value: data.label,
      }));
      return formatOptions;

    case Roles.AGED_CARE_SUPPORT_COORDINATOR:
    case Roles.DISABILITY_SUPPORT_COORDINATOR:
      return RolesOptionsForManager;

    default:
      return RolesOptionsForOwner;
    }
  };

  const handleClickSaveUser = () => {
    const isManager = role === Roles.ACCOUNT_MANAGER;
    const data = {
      firstName,
      lastName,
      email,
      role,
      ...(!isManager ? { userId } : null),
      ...(phoneInfo?.mobileNumber ? { mobile: phoneInfo.mobileNumber } : null),
    };
    handleSaveClicked(id, data, isManager, userOriginalRole);
  };

  useEffect(() => {
    if (selectedUser) {
      setId(selectedUser.id);
      setFirstName(selectedUser.firstName);
      setLastName(selectedUser.lastName);
      setEmail(selectedUser.email);
      setRole(getValue(selectedUser, "role.label", "recipient"));
      setUserRole(getValue(selectedUser, "role.label", "recipient"));
      setUserId(selectedUser.userId);

      const existingPhone = selectedUser.mobile || "";
      const phoneCountry = countryCodes.find((phone) => existingPhone.startsWith(phone.dial_code));
      if (existingPhone && phoneCountry) {
        const dial_code = phoneCountry?.dial_code;
        setCountry(phoneCountry.code);
        setPhoneDialCode(dial_code);
        const countryCodeLength = dial_code.length;
        setPhoneInfo({
          mobileNumber: existingPhone,
          numberOnly: existingPhone.substring(countryCodeLength, selectedUser.mobile?.length),
        });
      }
    }
  }, [selectedUser]);

  useEffect(() => {
    setIsRecipientManager(role === Roles.ACCOUNT_MANAGER);
  }, [role]);

  const handleNumberChange = (phone: string, countryData: any) => {
    const hasCountryCode = phone.startsWith(countryData.dialCode);
    setPhoneInfo({
      numberOnly: phone,
      mobileNumber: hasCountryCode ? `+${phone}` : `+${countryData.dialCode}${phone}`,
    });
  };

  const getSelectedRole = ({ roleOptions, role }: any) => {
    const selectedOption = roleOptions?.find((roleOption: any) => roleOption.value === role);

    console.log("this is selected option and role", { selectedOption, roleOptions, role });

    if (!selectedOption) {
      if (role === Roles.ACCOUNT_OWNER) {
        return {
          title: "Account Owner",
          value: Roles.ACCOUNT_OWNER,
        };
      } else if (role === Roles.ACCOUNT_MANAGER) {
        return {
          title: "Account Manager",
          value: Roles.ACCOUNT_MANAGER,
        };
      } else if (role === Roles.DISABILITY_SUPPORT_COORDINATOR) {
        return {
          title: "Disability Support Coordinator",
          value: Roles.DISABILITY_SUPPORT_COORDINATOR,
        };
      } else if (role === Roles.DISABILITY_SUPPORT_RECIPIENT) {
        return {
          title: "Disability Support Recipient",
          value: Roles.DISABILITY_SUPPORT_RECIPIENT,
        };
      } else if (role === Roles.AGED_CARE_SUPPORT_COORDINATOR) {
        return {
          title: "Aged Care Support Coordinator",
          value: Roles.AGED_CARE_SUPPORT_COORDINATOR,
        };
      } else if (role === Roles.AGED_CARE_SUPPORT_RECIPIENT) {
        return {
          title: "Aged Care Support Recipient",
          value: Roles.AGED_CARE_SUPPORT_RECIPIENT,
        };
      }
    }

    if (!role && roleOptions.length === 1) {
      setRole(roleOptions[0].value);
      return roleOptions[0];
    }

    return selectedOption;
  };

  const shouldShowAssignManagerOption = (role: string) => {
    if ([Roles.ACCOUNT_MANAGER, Roles.ACCOUNT_OWNER].includes(userRole) && ![Roles.ACCOUNT_MANAGER, Roles.ACCOUNT_OWNER].includes(role)) return true;

    return false;
  };

  return (
    <Dialog open={open} maxWidth={false} fullScreen={isMobile} onClose={onClose}>
      <Box bgcolor="white" width={isMobile ? "100%" : "448px"} borderRadius="8px">
        <Box paddingX={"48px"} mb={"32px"} pt={"40px"} position={"relative"}>
          <Box
            position={"absolute"}
            padding={"12px"}
            style={{ top: 0, right: 0, cursor: "pointer" }}
            onClick={onClose}
          >
            <img src={CloseIcon} alt="Close Icon" />
          </Box>
          <Typography style={{ fontSize: "30px", fontWeight: 600, color: Colors.NightBlue }}>
            {!!id ? "Edit User" : "Invite new user"}
          </Typography>
        </Box>
        <Box paddingLeft="48px" paddingRight="48px" mb="32px">
          <Box
            display="flex"
            flexDirection={isMobile ? "column" : "row"}
            gridGap={"24px"}
            mb="32px"
          >
            <TextField
              title="First name"
              value={firstName}
              onChange={(text) => setFirstName(text)}
              style={{ padding: 0 }}
            />
            <TextField
              title="Last name"
              value={lastName}
              onChange={(text) => setLastName(text)}
              style={{ padding: 0 }}
            />
          </Box>

          <TextField
            title="Email"
            placeholder="your@email.com"
            value={email}
            onChange={(text) => setEmail(text)}
            type="email"
            style={{ padding: 0, marginBottom: "32px" }}
          />

          {isRecipientManager ? (
            <Box mb={"32px"}>
              <Typography style={{ fontSize: "14px", fontWeight: 700, color: Colors.Dusk }}>
                Mobile number
              </Typography>
              <Box
                display="flex"
                flexDirection="column"
                style={{ width: "100%" }}
                className="verifyPhonePicker"
              >
                <PhoneInput
                  value={phoneInfo.mobileNumber}
                  masks={{ au: "... ... ..." }}
                  prefix={"+"}
                  countryCode={phoneDialCode}
                  country={country}
                  placeholder={"400 000 000"}
                  inputStyle={{ width: "100%", height: "48px", paddingLeft: "90px" }}
                  onChange={(phone: any, countryData: any) => {
                    handleNumberChange(phone, countryData);
                  }
                  }
                  inputProps={
                    {
                      value: getCountryCode(phoneInfo.mobileNumber)?.withoutCountryCode,
                    }
                  }
                />
              </Box>
            </Box>
          ) : (
            <></>
          )}

          <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
            <Dropdown
              title="Role"
              options={roleOptions()}
              selectedOption={getSelectedRole({ roleOptions: roleOptions(), role })}
              onSelectedOption={(option) => setRole(option.value)}
              paddingBottom={0}
              paddingTop={0}
              isDisabled={ifEditUserLoggedInUser()}
            />
          </Box>

          {(managerOptions && shouldShowAssignManagerOption(role)) && (
            <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
              <Dropdown
                title="Assign account manager"
                options={managerOptions}
                selectedOption={managerOptions.find((manager) => manager.value === userId)}
                onSelectedOption={(option) => setUserId(option.value)}
              />
            </Box>
          )}
        </Box>
      </Box>

      <Box
        display={"flex"}
        padding={"24px"}
        gridGap={"16px"}
        border={`1px solid ${Colors.LightPeriwinkle}`}
      >
        <Button
          type={ButtonType.outlined}
          size={ButtonSize.large}
          title="Cancel"
          onClick={() => onClose()}
        />
        <Button
          size={ButtonSize.large}
          type={ButtonType.indigo}
          title={selectedUser ? "Update" : canInviteUser ? "Invite" : "Confirm"}
          loading={isLoading}
          onClick={handleClickSaveUser}
        />
      </Box>
    </Dialog>
  );
};

export default AddUserModal;
