export enum Colors {
  White = "#ffffff",
  NightBlue = "#100249",
  Indigo = "#301E79",
  Dusk = "#41506f",
  TurquoiseBlue = "#0daec8",
  BlueyGrey = "#8b96ad",
  CoolBlue = "#49b5c6",
  LightPeriwinkle = "#dcdfe6",
  LightBlueGrey = "#c5cbd6",
  LightCyan = "#b9e5ec",
  PaleLilac = "#eaeaed",
  VeryLightPink = "#e5e5e5",
  Snow = "#FDFBFB",
  SlateGrey = "#64676d",
  Tomato = "#f14624",
  PaleGrey = "#f8f9fa",
  VeryLightGreen = "#d5f1cd",
  Cancelled = "#FFC8CF",
  Pale = "#feddcb",
  VeryLightBlue = "#e5e7ed",
  PalePink = "#ffd3da",
  GoldenYellow = "#f6c51f",
  LightGoldenYellow = "#FFE69C",
  Crimson = "#DB1313",
  SecondaryPink = "#FFB7C3",
  Champagne = "#FEDDCB",
  Peppermint = "#D5F1CD",
  Purple = "#B9C1EC",
  Red = "#ECBFB9",
  PowderBlue = "#B9E5EC",
  Grey = "#8B96AD",
  LightPale = "#FAF1EB",
  Danger = "#DC3545",
  TextDanger = "#EA3323",
  CornflowerBlue = "#EBF8FA",
  Invoice = "#556B88",
  DarkGrey = "#485585",
  ProAppGrey = "#393A3C",
  LightRed = "#EF8682",
  LightGrey = "#F4F1F1",
  Transparent = "rgba(0,0,0,0)",
  AppGrey = "#666666",
  LightTorquiseBlue = "#78CCE3",
  BrightGray = "#EFEFEF",
}
