import { Box, BoxProps } from "@material-ui/core";
import { isEmpty } from "lodash";
import { Bar } from "react-chartjs-2";
import { Chart } from "chart.js";
import SegmentedControl from "../../../components/SegmentedControl";
import { Colors } from "../../../constants/colors";
import { useUserStore } from "../../../stores/user";
import Button, { ButtonType } from "../../../components/Button";
import { Paths } from "../../../constants/paths";
import { useHistory } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { HelpOutline } from "@mui/icons-material";
import InfoModal from "../../../components/Modals/InfoModal/InfoModal";


Chart.defaults.font.size = 14;
Chart.defaults.font.family = "Open Sans";
Chart.defaults.color = Colors.Dusk;

type ChartProps = {
  chartSegments: any;
  selectedSegmentIndex: number
  setSelectedSegmentIndex: any
  isLoading: boolean
  chartData: () => {
    labels: string[];
    datasets: { label: string; data: { x: string; y: number; }[]; backgroundColor: Colors; }[];
  } | null
  viewReportsButton?: boolean
}

const Container = (props: BoxProps) => {
  return (
    <Box
      bgcolor="white"
      p="24px"
      border="1px solid #DCDFE6"
      borderRadius="8px"
      {...props}
    />
  );
};

const EarningsGraph = ({
  chartSegments,
  selectedSegmentIndex,
  setSelectedSegmentIndex,
  isLoading,
  chartData,
  viewReportsButton = false,
}: ChartProps) => {
  const { currency } = useUserStore();
  const history = useHistory();
  const chartRef = useRef(null);
  const [legendItems, setLegendItems] = useState([]);
  const [infoModal, setInfoModal] = useState({
    visible: false,
    content: {
      title: "",
      description: "",
      data: [],
    },
  });
  const chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    animations: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
        stacked: true,
      },
      y: {
        stacked: true,
        ticks: {
          color: Colors.Dusk,
          // format in currency
          callback: function(value: number, _index: any, _ticks: any) {
            return value.toPriceString(currency, 0);
          },
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          // Format labels to show as price
          label: function(context: any) {
            var label = context.dataset.label || "";

            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y.toPriceString(currency);
            }
            return label;
          },
        },
      },
      legend: {
        display: false,
      },
    },
  };

  useEffect(() => {
    const chart: any = chartRef.current;
    if (chart) {
      setLegendItems(chart.legend.legendItems);
    }
  }, [selectedSegmentIndex, chartRef, chartData]);

  const handleInfoIconClicked = (title: string, description: string = "") => {
    if (title === "Completed bookings") {
      description = "Shows total earnings from all completed bookings based on actual booking completed date. These figures may be different from your actual payout report and should not be used for accounting purposes. Please use the CSV report below for accounting purposes.";
    }
    if (title === "Upcoming bookings") {
      description = "Shows future earnings from all confirmed upcoming bookings based on estimated booking completed date. These figures may be different from your actual payout report and should not be used for accounting purposes. Please use the CSV report below for accounting purposes.";
    }
    const modalContent = {
      title: title || "",
      description: description || "",
      data: [],
    };
    setInfoModal({
      visible: true,
      content: modalContent,
    });
  };

  return (
    <Container position="relative">
      <SegmentedControl
        segments={chartSegments}
        selectedSegmentIndex={selectedSegmentIndex}
        onSelectedIndex={(index) => setSelectedSegmentIndex(index)}
      />

      <Box height={viewReportsButton ? "245px" : "400px"} mt={viewReportsButton ? 1.65 : 3}
        pb={viewReportsButton ? 2 : 3}>
        {(
          <>
            {/* Custom HTML legend */}
            <Box style={{
              display: "flex",
              marginTop: 4,
              marginBottom: 8,
              justifyContent: !viewReportsButton ? "center" : "flex-start",
            }}>
              {legendItems.map((item: any, index) => (
                <Box key={index} style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: 24,
                }}>
                  <Box
                    style={{
                      backgroundColor: item.fillStyle,
                      borderRadius: 2, // Add rounded corners here
                      display: "inline-block",
                      width: 16,
                      height: 16,
                      marginRight: 4,
                    }}
                  ></Box>
                  <Box style={{ fontFamily: "Open Sans", fontSize: 14, color: Colors.Dusk }}>
                    {item.text}
                  </Box>
                  <Box onClick={(e) => {
                    e.stopPropagation();
                    handleInfoIconClicked(item.text);
                  }} style={{ color: Colors.Dusk }}>
                    <HelpOutline color="inherit" htmlColor={Colors.Dusk}
                      style={{ cursor: "pointer", marginTop: 5, height: 16, color: Colors.Dusk }} />
                  </Box>
                </Box>
              ))}
            </Box>
            <Bar
              data={chartData()}
              options={chartOptions}
              ref={chartRef}
            />
          </>
        )}
      </Box>
      {!isLoading && isEmpty(chartData()) && chartData() === null && (
        <Box
          position="absolute"
          top={"50%"}
          ml="auto"
          mr="auto"
          left={0}
          right={0}
          textAlign="center"
          color={Colors.Dusk}
          fontWeight={400}
          fontSize="16px"
          lineHeight="24px"
          fontFamily="Museo"
        >
          No bookings have been completed
        </Box>
      )}
      {viewReportsButton &&
        <Button type={ButtonType.outlined} style={{ color: Colors.Dusk, marginTop: 24 }} colorTitle={Colors.Dusk}
          title="View reports" onClick={() => {
            history.push(Paths.ProDashboardReports);
          }}></Button>}
      {infoModal.visible && <InfoModal
        visible={infoModal.visible}
        title={infoModal.content.title}
        description={infoModal.content.description}
        data={infoModal.content.data}
        divider={false}
        handleClose={() => {
          setInfoModal({
            visible: false,
            content: {
              title: "",
              description: "",
              data: [],
            },
          });
        }}
      />}
    </Container>
  );
};

export default EarningsGraph;
