import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { RecurringType } from "../../../constants/booking";
import { QUESTION_TYPE } from "../../../constants/questionType";
import { RECURRING_ENDS } from "../../../constants/time";
import { BOOKING_SESSION_TYPE } from "../../../helpers/booking";
import { checkIfEmpty, checkIfUndefined, getValue } from "../../../utils/object";
import { getBookingTitleByDetails, getQuestionsForTreatment } from "./booking.transformer";

interface TreatmentDetail {
  title: string;
  value: string;
  questions: any[];
  treatmentFor: string;
  deliveryMethod: string;
  serviceDuration?: number | null;
  treatmentDuration?: number | null;
  noteForProvider?: string;
  genderPreference: string;
  massageType: string;
  addons: any[];
}

const defaultTreatmentDetails: TreatmentDetail = {
  title: "",
  value: "",
  questions: [],
  treatmentFor: "",
  deliveryMethod: "",
  serviceDuration: null,
  treatmentDuration: null,
  noteForProvider: "",
  genderPreference: "",
  massageType: "",
  addons: [],
};

const defaultSelectedService = {
  id: 0,
  name: "",
  alias: "",
  category: "",
  sessionType: "",
  isMassage: false,
  isHairAndMakeup: false,
  isBeauty: false,
  treatments: [],
  prices: [],
  duration: [],
  genderPreferences: [],
  sessionTypes: [],
};

const initialPrice = {
  price: 0,
  originalPrice: 0,
  hotelSurcharge: 0,
  clientPlatformFee: 0,
  lateNightSurcharge: 0,
  priceWithoutDiscount: 0,
  publicHolidaySurcharge: 0,
  clientPlatformFeeData: {},
  peakHourInfo: {},
};

const defaultBookingDetail: any = [
  {
    jobId: null,
    status: "",
    therapist: {},
    treatmentDetails: [defaultTreatmentDetails],
    serviceDuration: null,
  },
];

interface UpdatedFields {
  timeOfArrival?: string;
}

interface BookingAddress {
  id: number;
  countryCode: string;
}

const initialState = {
  id: null,
  status: "",
  title: "",
  subtitle: "",
  address: {} as BookingAddress,
  allServices: [],
  selectedRecipient: null,
  selectedSessionType: {
    title: "",
    value: "",
    isSingle: true,
    isBackToBack: false,
    isCouples: false,
  },
  userId: null,
  questions: [],
  treatmentDetails: [defaultTreatmentDetails],
  jobStatus: "",
  selectedService: defaultSelectedService,
  bookingDetails: defaultBookingDetail,
  bookingPrice: { ...initialPrice },
  bookingLink: null,
  backup: null,
  coupon: {
    code: null,
    value: null,
  },
  channel: null,
  credituses: [],
  updated: {},
  isUpdated: false,
  isFetching: true,
  isManual: false,
  isActuallyPaid: false,
  isHomeCare: false,
  isHairAndMakeup: false,
  timeOfArrival: moment(),
  timezone: "Australia/Sydney",
  specialInstructions: null,
  instructions: {
    note: null,
    preference: null,
    contraindications: null,
  },
  selectedDateMoment: moment(),
  selectedServiceDuration: [],
  selectedLatestTimeInMinutes: 0,
  selectedEarliestTimeInMinutes: 0,
  bookingUpdates: [],
  peakHourInfo: {},
  platformFeeData: {},
  isPlatformFeeApplied: true,
  cancellationFee: null,
  existingBooking: {
    timeOfArrival: null,
    treatmentDetails: [],
    recurring: {
      frequency: null,
      isSameProvider: null,
      type: RecurringType.WEEK,
      isCustom: false,
      monthly: {
        dayOfMonth: null,
        dayOfWeek: null,
        weekOfMonth: null,
      },
    },
    recuringEndOn: {
      endsOn: null,
      type: RECURRING_ENDS.NEVER,
      occurrence: 1,
    },
    address: {} as BookingAddress,
    selectedRecipient: null,
    bookingDetails: [],
    preferredTherapists: null,
  },
  paymentmethod: {},
  paymentmethodId: null,
  paymentType: null,
  afterpayOrderToken: null,
  braintreeTransactionId: null,
  corporateInfo: null,
  sessionType: null,
  isB2B: false,
  frequency: null,
  recurring: {
    frequency: null,
    isSameProvider: null,
    type: RecurringType.WEEK,
    isCustom: false,
    monthly: {
      dayOfMonth: null,
      dayOfWeek: null,
      weekOfMonth: null,
    },
    isRepeating: false,
    shouldCreateFutureBookings: false,
  },
  recuringEndOn: {
    endsOn: null,
    type: RECURRING_ENDS.NEVER,
    occurrence: 1,
  },
  proAddOns: [],
  serviceFee: 0,
  uuid: null,
  numberOfClients: 1,
};

const { reducer, actions } = createSlice({
  name: "booking",
  initialState,
  reducers: {
    clearUpdates: (state, action) => {
      return {
        ...state,
        updated: {},
      };
    },
    clearFieldUpdate: (state, action) => {
      const newUpdated: UpdatedFields = { ...state.updated };
      const { field } = action.payload;

      if (field === "timeOfArrival" && newUpdated.timeOfArrival) delete newUpdated.timeOfArrival;
      return { ...state, updated: newUpdated };
    },
    updateBookingPrice: (state, action) => {
      const { price } = action.payload;
      if (price) {
        return { ...state, ...{ bookingPrice: price } };
      }
      return { ...state };
    },

    toggleFetching: (state, action) => {
      const { isFetching } = action.payload;
      return { ...state, isFetching };
    },

    updateSessionType: (state, action) => {
      const { sessionType } = action.payload;
      if (state.selectedSessionType.value === sessionType) return;

      // restrict session type change for couples or to couples
      if (state.selectedSessionType.value === BOOKING_SESSION_TYPE.COUPLES) return;
      if (sessionType === BOOKING_SESSION_TYPE.COUPLES) return;

      let updatedDetails = state.bookingDetails;
      let serviceDurations = state.selectedServiceDuration;
      // if back-to-back, duplicate treatment details for person 2
      if (sessionType === BOOKING_SESSION_TYPE.BACK_TO_BACK) {
        updatedDetails[0].treatmentDetails.push(updatedDetails[0].treatmentDetails[0]);
        serviceDurations.push(state.selectedServiceDuration[0]);
      }
      // if singles, create a single treatment detail
      else if (sessionType === BOOKING_SESSION_TYPE.SINGLES) {
        updatedDetails[0].treatmentDetails = [updatedDetails[0].treatmentDetails[0]];
        serviceDurations.pop();
      }
      state.selectedServiceDuration = serviceDurations;

      const { sessionTypes } = state.selectedService;
      const selectedSessionType = sessionTypes.find(({ value }) => value === sessionType);
      if (selectedSessionType && !checkIfEmpty(selectedSessionType)) {
        state.selectedSessionType = selectedSessionType;
      }
      state.updated = { ...state.updated, sessionType };
    },

    updateService: (state, action) => {
      const { serviceId } = action.payload;
      const { allServices, selectedService, questions, updated } = state;

      if (serviceId === selectedService.id) return;

      const targetService: any = allServices.find(({ id }) => serviceId === id);
      if (!targetService || checkIfEmpty(targetService.prices)) return;

      handleServiceChange(state, { selectedService: targetService, questions });
      state.updated = {
        ...updated,
        serviceId: targetService.id,
        bookingDetails: state.bookingDetails,
      };
    },

    updateRecipientCount: (state, action) => {
      const { numberOfRecipient } = action.payload;
      const { updated } = state;
      const currentCount = (getValue(state, "bookingDetails[0].treatmentDetails") || []).length;

      const currentDetails = state.bookingDetails[0].treatmentDetails;

      const countDiff = Math.abs(currentCount - numberOfRecipient);
      if (numberOfRecipient > currentCount) {
        const details = { ...currentDetails[0] };

        // filter out service questions
        details.questions = (details.questions || []).filter(
          ({ treatmentId }: any) => !!treatmentId
        );
        for (let i = 0; i < countDiff; i += 1) currentDetails.push(details);
      } else if (numberOfRecipient < currentCount) {
        for (let i = 0; i < countDiff; i += 1) currentDetails.pop();
      }
      state.bookingDetails[0].treatmentDetails = currentDetails;
      state.numberOfClients = numberOfRecipient;
      state.bookingDetails[0].serviceDuration = getTotalDurationFromDetails({
        treatmentDetails: currentDetails,
      });

      state.updated = { ...updated, bookingDetails: state.bookingDetails };
    },

    updateTreatmentDetails: (state, action) => {
      const { selectedTreatment, selectedDuration, index: selectedIndex } = action.payload;

      const { isMassage } = state.selectedService;

      if (isMassage) {
        handleMassageChange(state, { selectedTreatment, selectedDuration, selectedIndex });
      } else {
        handleHairAndMakeupChange(state, { selectedTreatment, selectedIndex });
      }
      const { updated } = state;
      state.updated = {
        ...updated,
        bookingDetails: state.bookingDetails,
      };
      state.isUpdated = true;
    },

    updateSelectedAnswer: (state, action) => {
      const {
        answer: selectedAnswer,
        questionId,
        treatmentIndex,
        answers: selectedAnswers,
      } = action.payload;
      const { bookingDetails } = state;

      let questions = [];
      if (state.selectedService.isMassage) {
        const selectedTreatment =
          getValue(bookingDetails[treatmentIndex], "treatmentDetails[0]") ||
          getValue(bookingDetails, `[0].treatmentDetails[${treatmentIndex}]`);
        questions = selectedTreatment.questions;
      } else {
        const selectedTreatment = bookingDetails[0];
        questions = selectedTreatment.treatmentDetails[treatmentIndex].questions;
      }
      const targetQuestion = questions.find(({ id }: { id: number }) => id === questionId);
      if (targetQuestion) {
        let answer = {};
        if (targetQuestion.type === QUESTION_TYPE.SELECT) {
          const { value } = selectedAnswer;
          answer = {
            questionId,
            answer: value,
            answers: null,
            type: QUESTION_TYPE.SELECT,
          };
        } else if (targetQuestion.type === QUESTION_TYPE.IMAGE) {
          answer = {
            questionId,
            answer: null,
            answers: selectedAnswers,
            type: QUESTION_TYPE.IMAGE,
          };
        } else if (targetQuestion.type === QUESTION_TYPE.DURATION) {
          const { value } = selectedAnswer;
          answer = {
            questionId,
            answer: value,
            answers: null,
            type: QUESTION_TYPE.DURATION,
          };
        }
        targetQuestion.answer = answer;
      }
      const { updated } = state;
      state.updated = {
        ...updated,
        bookingDetails: state.bookingDetails,
      };
      state.isUpdated = true;

      bookingDetails.forEach((detail: any) => {
        if (!checkIfEmpty(detail)) {
          const treatmentDetails = detail.treatmentDetails || [];
          detail.serviceDuration = getTotalDurationFromDetails({ treatmentDetails });
        }
      });
    },

    // add item here which needs to be updated
    updateBookings: (state, action) => {
      const { timeOfArrival } = action.payload;
      return {
        ...state,
        ...{
          timeOfArrival,
          updated: {
            ...state.updated,
            timeOfArrival: timeOfArrival.format(),
          },
        },
      };
    },

    // booking frequency
    updateBookingFrequency: (state, action) => {
      const { frequency, isRepeating } = action.payload;
      const updatedData = {
        frequency: frequency,
        isSameProvider: state.recurring.isSameProvider,
        isRepeating: typeof isRepeating === "boolean" ? isRepeating : state.recurring.isRepeating,
      };
      return {
        ...state,
        ...{ updated: { ...state.updated, recurring: { ...state.recurring, ...updatedData } } },
        ...{ recurring: { ...state.recurring, ...updatedData } },
      };
    },

    updateIsCustomRecurringType: (state, action) => {
      const { isCustom } = action.payload;
      const updatedData = {
        ...state.recurring,
        isCustom: isCustom,
      };
      return {
        ...state,
        ...{ updated: { ...state.updated, recurring: updatedData } },
        ...{ recurring: updatedData },
      };
    },

    updateShouldCreateFutureBookings: (state, action) => {
      const { shouldCreateFutureBookings } = action.payload;
      const updatedData = {
        ...state.recurring,
        shouldCreateFutureBookings,
      };
      return {
        ...state,
        ...{ updated: { ...state.updated, recurring: updatedData } },
        ...{ recurring: updatedData },
      };
    },

    updateRecurringType: (state, action) => {
      const { recurringType } = action.payload;
      const updatedData = {
        ...state.recurring,
        type: recurringType,
      };
      return {
        ...state,
        ...{ updated: { ...state.updated, recurring: updatedData } },
        ...{ recurring: updatedData },
      };
    },

    updateMonthData: (state, action) => {
      const { monthly } = action.payload;
      const updatedData = {
        ...state.recurring,
        monthly,
      };
      return {
        ...state,
        ...{ updated: { ...state.updated, recurring: updatedData } },
        ...{ recurring: updatedData },
      };
    },

    updateRecurringMonthDataForDayOfMonth: (state, action) => {
      const { dayOfMonth } = action.payload;
      const updatedData = {
        ...state.recurring,
        monthly: {
          dayOfMonth,
          weekOfMonth: null,
          dayOfWeek: null,
        },
      };

      return {
        ...state,
        ...{ updated: { ...state.updated, recurring: updatedData } },
        ...{ recurring: updatedData },
      };
    },

    updateRecurringMonthDataForWeekOfMonth: (state, action) => {
      const { weekOfMonth, dayOfWeek } = action.payload;
      const updatedData = {
        ...state.recurring,
        monthly: {
          dayOfMonth: null,
          weekOfMonth,
          dayOfWeek,
        },
      };
      return {
        ...state,
        ...{ updated: { ...state.updated, recurring: updatedData } },
        ...{ recurring: updatedData },
      };
    },

    resetMonthlyDataForCustomFrequency: (state) => {
      const updatedData = {
        ...state.recurring,
        monthly: {
          dayOfMonth: null,
          weekOfMonth: null,
          dayOfWeek: null,
        },
      };
      return {
        ...state,
        ...{ updated: { ...state.updated, recurring: updatedData } },
        ...{ recurring: updatedData },
      };
    },

    // update booking recurring endsOn
    updateBookingRecurringEndsOn: (state, action) => {
      const { data } = action.payload;
      const updatedData = { endsOn: data?.endsOn, type: data?.type, occurrence: data?.occurrence };
      return {
        ...state,
        ...{
          updated: { ...state.updated, recuringEndOn: updatedData, recurring: state.recurring },
        },
        ...{ recuringEndOn: updatedData },
      };
    },

    updateIsSameProvider: (state, action) => {
      const { isSameProvider } = action.payload;
      const updatedData = {
        frequency: state.recurring.frequency,
        isSameProvider: isSameProvider,
      };
      return {
        ...state,
        ...{ updated: { ...state.updated, recurring: { ...state.recurring, ...updatedData } } },
        ...{ recurring: { ...state.recurring, ...updatedData } },
      };
    },

    updateExistingBookingFrequency: (state, action) => {
      const { frequency } = action.payload;
      const updatedData = {
        frequency,
      };

      return {
        ...state,
        ...{ updated: { ...state.updated, ...updatedData } },
        ...{ existingBooking: { ...state.existingBooking, ...updatedData } },
      };
    },

    updateTreatmentAddOn: (state, action) => {
      const { addon, index: selectedIndex, isMultipleAddonsAllowed } = action.payload;

      const { isMassage } = state.selectedService;

      if (isMassage) {
        handleAddOnChangeForMassage(state, {
          addon,
          selectedIndex,
          isMultipleAddonsAllowed,
        });
      } else {
        handleAddOnChangeForHairAndMakeUp(state, { addon, selectedIndex, isMultipleAddonsAllowed });
      }
      const { updated } = state;
      state.updated = {
        ...updated,
        bookingDetails: state.bookingDetails,
      };
      state.isUpdated = true;
    },

    resetTreatmentAddOn: (state, action) => {
      const { index: selectedIndex, treatmentId } = action.payload;

      const { isMassage } = state.selectedService;

      // if (
      //   isMassage &&
      //   newMassageTreatmentDetails[treatmentIndex].treatmentTypeId !== treatmentId
      // ) {
      //   newMassageTreatmentDetails[treatmentIndex].addons = [];
      // } else if (!isMassage && newTreatmentDetails[treatmentIndex].treatmentId !== treatmentId) {
      //   newTreatmentDetails[treatmentIndex].addons = [];
      // }
      if (isMassage) {
        resetAddOnForMassage(state, {
          selectedIndex,
          treatmentId,
        });
      } else {
        resetAddOnForHairAndMakeUp(state, { selectedIndex, treatmentId });
      }
      const { updated } = state;
      state.updated = {
        ...updated,
        bookingDetails: state.bookingDetails,
      };
      state.isUpdated = true;
    },

    updateProdAddOn: (state, action) => {
      const { addons } = action.payload;

      return {
        ...state,
        proAddOns: addons,
      };
    },

    updateBookingLocation: (state, action) => {
      const { address } = action.payload;
      return {
        ...state,
        updated: { ...state.updated, address: address },
        address: address,
      };
    },
    
    updateBookingArrivalTime: (state, action) => {
      const { timeOfArrival } = action.payload;
      return {
        ...state,
        updated: { ...state.updated, timeOfArrival: timeOfArrival },
        timeOfArrival,
      };
    },

    updateBookingRecipient: (state, action) => {
      const { recipient } = action.payload;
      return {
        ...state,
        ...{ updated: { ...state.updated, selectedRecipient: recipient } },
        selectedRecipient: recipient,
      };
    },

    initialize: (state, action) => {
      if (action.payload) {
        return {
          ...initialState,
          ...action.payload,
          updated: { ...state.updated },
          isUpdated: false,
          isFetching: false,
          existingBooking: {
            ...state.existingBooking,
            address: getValue(action.payload, "address"),
            selectedRecipient: getValue(action.payload, "selectedRecipient"),
            recurring: getValue(action.payload, "recurring"),
            recuringEndOn: getValue(action.payload, "recuringEndOn"),
            bookingDetails: getValue(action.payload, "bookingDetails"),
            timeOfArrival: getValue(action.payload, "timeOfArrival"),
            preferredTherapists: getValue(action.payload, "preferredTherapists"),
          },
        };
      }
      return { ...state };
    },
  },
});

const handleServiceChange = (state: any, { selectedService, questions = [] }: any) => {
  if (checkIfEmpty(selectedService)) return;

  state.selectedService = selectedService;
  const { isMassage, sessionTypes, isHairAndMakeup } = selectedService;
  if (isMassage && !checkIfEmpty(sessionTypes)) {
    state.selectedSessionType = sessionTypes[0];
  }

  const selectedTreatment = getValue(selectedService, "prices[0]");
  if (checkIfEmpty(selectedTreatment)) return;

  const { name, label, id: treatmentTypeId, duration } = selectedTreatment;
  const transformedQuestions = getQuestionsForTreatment({
    questions,
    answers: [],
    selectedService,
    treatmentIndex: 0,
    treatmentId: selectedTreatment.id,
  });

  let serviceDuration = duration;
  if (isMassage) {
    const { duration: durations } = selectedService;
    serviceDuration = !checkIfEmpty(durations) ? durations[0].value : null;
  }

  const treatmentDetail = {
    label,
    value: name,
    title: label,
    id: treatmentTypeId,
    treatment: selectedTreatment,
    questions: transformedQuestions,
    serviceDuration,
    addons: [],
  };

  const treatmentDetails = [];
  if (!isHairAndMakeup) {
    state.numberOfClients = 1;
    treatmentDetails.push(treatmentDetail);
    if (isMassage) state.selectedServiceDuration = [serviceDuration];
  } else {
    let totalDuration = 0;
    for (let i = 0; i < state.numberOfClients; i++) {
      totalDuration += treatmentDetail.serviceDuration;
      const detail = { ...treatmentDetail };
      if (i !== 0) {
        // filter out service questions
        detail.questions = detail.questions.filter((que) => !!que.treatmentId);
      }
      treatmentDetails.push(detail);
    }
    state.bookingDetails[0].serviceDuration = totalDuration;
  }

  state.bookingDetails[0].treatmentDetails = treatmentDetails;

  const updatedTitle = getBookingTitleByDetails({ service: selectedService, treatmentDetails });
  state.title = updatedTitle;
};

const handleMassageChange = (
  state: any,
  { selectedTreatment, selectedDuration, selectedIndex }: any
) => {
  const { questions, selectedService, bookingDetails } = state;
  const session = getValue(state, "selectedSessionType.value");

  let targetDetails =
    session === BOOKING_SESSION_TYPE.BACK_TO_BACK
      ? bookingDetails[0].treatmentDetails[selectedIndex]
      : bookingDetails[selectedIndex].treatmentDetails[0];

  let massageType, treatmentLabel;

  if (selectedTreatment) {
    massageType = selectedTreatment.name;
    treatmentLabel = selectedTreatment.label;

    const answersArr = targetDetails.questions.map(({ answer }: any) => answer);
    const transformedQuestions = getQuestionsForTreatment({
      questions,
      answers: answersArr,
      selectedService,
      treatmentIndex: selectedIndex,
      treatmentId: selectedTreatment.id,
    });

    targetDetails.questions = transformedQuestions;
  }

  if (selectedDuration) {
    const { selectedServiceDuration } = state;
    const updatedDurations = [...selectedServiceDuration];
    updatedDurations[selectedIndex] = selectedDuration;
    state.selectedServiceDuration = updatedDurations;
  }

  if (session === "backtoback") {
    if (selectedTreatment) {
      targetDetails = {
        ...targetDetails,
        value: massageType,
        title: treatmentLabel,
        id: selectedTreatment.id,
      };
    }
    if (selectedDuration) {
      targetDetails = { ...targetDetails, serviceDuration: selectedDuration };
    }
    const newArr = [...bookingDetails[0].treatmentDetails];
    newArr[selectedIndex] = targetDetails;
    bookingDetails[0].treatmentDetails = newArr;
  } else {
    if (selectedTreatment) {
      targetDetails = {
        ...targetDetails,
        value: massageType,
        title: treatmentLabel,
        id: selectedTreatment.id,
      };
    }
    if (selectedDuration) {
      targetDetails = { ...targetDetails, serviceDuration: selectedDuration };
    }
    const updatedDetails = [...state.bookingDetails];
    updatedDetails[selectedIndex].treatmentDetails = [targetDetails];
  }

  const details = (state.bookingDetails || [])
    .map(({ treatmentDetails }: any) => treatmentDetails)
    .flat();
  const title = getBookingTitleByDetails({
    service: state.selectedService,
    treatmentDetails: details,
  });
  state.title = title;
};

const handleHairAndMakeupChange = (state: any, { selectedTreatment, selectedIndex }: any) => {
  const { bookingDetails, selectedService, questions } = state;
  let targetDetails = bookingDetails[0].treatmentDetails[selectedIndex];
  if (!bookingDetails) return;

  if (selectedTreatment) {
    const { name, label, id, duration } = selectedTreatment;
    const answersArr = targetDetails.questions.map(({ answer }: any) => answer);
    const transformedQuestions = getQuestionsForTreatment({
      treatmentIndex: selectedIndex,
      questions,
      selectedService,
      treatmentId: id,
      answers: answersArr,
    });
    targetDetails = {
      ...targetDetails,
      id,
      value: name,
      title: label,
      label,
      questions: transformedQuestions,
      serviceDuration: duration,
    };
  }
  bookingDetails[0].treatmentDetails[selectedIndex] = targetDetails;

  const updatedTitle = getBookingTitleByDetails({
    service: selectedService,
    treatmentDetails: bookingDetails[0].treatmentDetails,
  });
  state.title = updatedTitle;
};

const handleAddOnChangeForMassage = (
  state: any,
  { addon, selectedIndex, isMultipleAddonsAllowed }: any
) => {
  const { bookingDetails } = state;
  const session = getValue(state, "selectedSessionType.value");

  const targetDetails =
    session === BOOKING_SESSION_TYPE.BACK_TO_BACK
      ? bookingDetails[0].treatmentDetails[selectedIndex]
      : bookingDetails[selectedIndex].treatmentDetails[0];

  if (targetDetails) {
    const existingAddOnIndex = targetDetails.addons?.findIndex(
      (data: any) => data.id === addon.id || data.id === addon?.treatmentAddons?.id
    );

    let updatedAddons;
    if (existingAddOnIndex !== -1) {
      const removeDuplicateAddOn =
        targetDetails.addons?.filter(
          (data: any) => data.id !== addon.id && data.id !== addon?.treatmentAddons?.id
        ) || [];
      updatedAddons = [...removeDuplicateAddOn];
    } else {
      const prevAddOns = targetDetails.addons || [];
      updatedAddons = isMultipleAddonsAllowed ? [...prevAddOns, addon] : [addon];
    }

    const updatedDetails = [...bookingDetails];
    if (session === BOOKING_SESSION_TYPE.BACK_TO_BACK) {
      updatedDetails[0].treatmentDetails[selectedIndex] = {
        ...targetDetails,
        addons: updatedAddons,
      };
    } else {
      updatedDetails[selectedIndex].treatmentDetails[0] = {
        ...targetDetails,
        addons: updatedAddons,
      };
    }
  }
};

const handleAddOnChangeForHairAndMakeUp = (
  state: any,
  { addon, selectedIndex, isMultipleAddonsAllowed }: any
) => {
  const { bookingDetails } = state;
  let targetDetails = bookingDetails[0].treatmentDetails[selectedIndex];

  if (!bookingDetails) return;

  if (targetDetails) {
    let existingAddOnIndex = targetDetails?.addons?.findIndex(
      (data: any) => data.id === addon.id || data.id === addon?.treatmentAddons?.id
    );

    if (existingAddOnIndex !== -1 && !checkIfUndefined(existingAddOnIndex)) {
      let removeDuplicateAddOn =
        targetDetails?.addons?.filter(
          (data: any) => data.id !== addon.id && data.id !== addon?.treatmentAddons?.id
        ) || [];

      targetDetails = {
        ...targetDetails,
        addons: [...removeDuplicateAddOn],
      };
    } else {
      const prevAddOns = targetDetails?.addons || [];
      targetDetails = {
        ...targetDetails,
        addons: isMultipleAddonsAllowed ? [...prevAddOns, addon] : [addon],
      };
    }
  }

  bookingDetails[0].treatmentDetails[selectedIndex] = targetDetails;
  bookingDetails[0].serviceDuration = getTotalDurationFromDetails({
    treatmentDetails: bookingDetails[0].treatmentDetails,
  });
};

const resetAddOnForMassage = (state: any, { selectedIndex, treatmentId }: any) => {
  const { bookingDetails } = state;
  const session = getValue(state, "selectedSessionType.value");

  const targetDetails =
    session === BOOKING_SESSION_TYPE.BACK_TO_BACK
      ? bookingDetails[0].treatmentDetails[selectedIndex]
      : bookingDetails[selectedIndex].treatmentDetails[0];

  if (checkIfEmpty(targetDetails)) return;

  const updatedDetails = [...bookingDetails];
  if (session === BOOKING_SESSION_TYPE.BACK_TO_BACK && targetDetails.id !== treatmentId) {
    updatedDetails[0].treatmentDetails[selectedIndex] = {
      ...targetDetails,
      addons: [],
    };
  } else if (targetDetails.id !== treatmentId) {
    updatedDetails[selectedIndex].treatmentDetails[0] = {
      ...targetDetails,
      addons: [],
    };
  }
};

const resetAddOnForHairAndMakeUp = (state: any, { selectedIndex }: any) => {
  const { bookingDetails } = state;
  if (!bookingDetails) return;

  let targetDetails = bookingDetails[0].treatmentDetails[selectedIndex];
  if (targetDetails) {
    targetDetails = {
      ...targetDetails,
      addons: [],
    };
  }

  bookingDetails[0].treatmentDetails[selectedIndex] = targetDetails;
  bookingDetails[0].serviceDuration = getTotalDurationFromDetails({
    treatmentDetails: bookingDetails[0].treatmentDetails,
  });
};

const getTotalDurationFromDetails = ({ treatmentDetails = [] }: any) => {
  let treatmentDuration = 0;

  const details = treatmentDetails || [];
  details.forEach((detail: any) => {
    if (!checkIfEmpty(detail)) {
      treatmentDuration += getValue(detail, "serviceDuration") || 0;
      const { questions, addons } = detail;

      let answerDuration = 0;
      questions.forEach(({ answer, answerDetails }: any) => {
        const selectedAnswer = answerDetails.find(({ value }: any) => value === answer.answer);
        answerDuration += getValue(selectedAnswer, "addedDuration") || 0;
      });

      let addonDuration = (addons || []).reduce(
        (acc: number, addon: any) => (addon ? addon.duration : 0),
        0
      );
      treatmentDuration += answerDuration + addonDuration;
    }
  });

  return treatmentDuration;
};

export { actions };
export default reducer;
